/* Todo : Update font based on App Design */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@400&display=swap");

@font-face {
  font-family: "BCGHendersonMod";
  src: url("../assets/fonts/Henderson BCG Mod/BCG Henderson Mod-Regular.otf") format("opentype"); 
  font-weight: 400;
}

@font-face {
  font-family: "BCGHendersonMod";
  src: url("../assets/fonts/Henderson BCG Mod/BCG Henderson Mod-Bold.otf") format("opentype"); 
  font-weight: 700;
}

@font-face {
  font-family: "BCGHendersonSans";
  src: url("../assets/fonts/BCG Henderson Sans/BCGHenSansRegular.ttf") format("truetype"); 
  font-weight: 400;  
}

@font-face {
  font-family: "BCGHendersonSans";
  src: url("../assets/fonts/BCG Henderson Sans/BCGHenSansBold.ttf") format("truetype"); 
  font-weight: 700;
}

$font-stack: "BCGHendersonSans", "BCGHendersonMod", "Open Sans";

#root {
  padding-top: 50px;
  min-width: 1440px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  outline: none;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
textarea {
  resize: none;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}