.react-datepicker-wrapper {
  width: 100%;
  background-color: #fff;
}
.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
  background-color: #fff;
}
.react-datepicker__input-container > input {
  font-family: inherit;
  font-size: 14px !important;
  color: #000 !important;
  padding: 0.9rem 4rem 1rem 1rem;
  border-radius: 4px;
  border: 1px solid #c8c8c8 !important;
  background-image: url(../assets/icon_calendar.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
  //width: 100%;
  min-width: 11rem;
  cursor: pointer;
}
.react-datepicker__input-container > input:disabled {
  opacity: 0.5;
}
.react-datepicker__input-container > input.dense {
  padding: 0.8rem 4rem 0.8rem 1rem;
}
.react-datepicker__input-container > input.disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.react-datepicker {
  font-family: inherit;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}
.react-datepicker__day {
  margin: 0.5rem;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.4rem !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #1d4659;
  width: 2.5rem;
  border-radius: 10px;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.2rem;
}
.react-datepicker-popper[data-placement^="bottom"] {
  background-color: transparent;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.5rem;
}
